.App {
  text-align: center;
}

body,
html {
  width: 100%;
  height: 100%; }

body {
  /*font-family: 'Source Sans Pro';*/ 
}

#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: #1D809F;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none; }

.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px; }

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2); }

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none; }

.sidebar-nav > .sidebar-brand {
  font-size: 1.2rem;
  background: rgba(52, 58, 64, 0.1);
  height: 80px;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px; }

.sidebar-nav > .sidebar-brand a {
  color: #fff; }

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none; }

#sidebar-wrapper.active {
  right: 250px;
  width: 250px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 50px;
  z-index: 999; }
  .menu-toggle:focus, .menu-toggle:hover {
    color: #fff; }
  .menu-toggle:hover {
    background: #343a40; 
  }

.titles {
  color: #ecb807!important;
}

.home-title{
  padding-top: 25vh;
  padding-bottom: 25vh;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: #155d74!important;
  border-color: #155d74!important;
}

.about {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  background-color: #ffffff!important;
}

.portfolio {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  /*background-color: #1d809f!important;*/
}

.skills{
  background-color: #1d809f!important;
}

.contact {
  padding-top: 30px;
  width: 100%;
  height: auto;
  background-color: #1d809f !important;
}

#drawer{
  background-color: #1d909f !important;
  color: #ffffff !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px; }
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: white; }
  .scroll-to-top:hover {
    background: #343a40; }
  .scroll-to-top i {
    font-weight: 800; 
  }

.content-section {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important; 
}

.content-section-heading h2 {
  font-size: 3rem; }

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase; }

  .service-icon {
    background-color: #fff;
    color: #1D809F;
    height: 7rem;
    width: 7rem;
    display: block;
    line-height: 7.5rem;
    font-size: 2.25rem;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
  }
  
.caption-content > h2{
  text-align: left;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.caption-content > p{
  text-align: left;
  font-weight: bold;
}

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem; }
  .portfolio-item .caption {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(33, 37, 41, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1; }
    .portfolio-item .caption .caption-content {
      color: #fff;
      margin: auto 2rem 2rem; }
      .portfolio-item .caption .caption-content h2 {
        font-size: 0.8rem;
        text-transform: uppercase; }
      .portfolio-item .caption .caption-content p {
        font-weight: 300;
        font-size: 1.2rem; }
  @media (min-width: 992px) {
    .portfolio-item {
      max-width: none;
      margin: 0; }
      .portfolio-item .caption {
        -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
        -webkit-clip-path: inset(0px);
        clip-path: inset(0px); }
        .portfolio-item .caption .caption-content {
          transition: opacity 0.25s;
          margin-left: 5rem;
          margin-right: 5rem;
          margin-bottom: 5rem; }
      .portfolio-item img {
        -webkit-transition: -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(-1px);
        clip-path: inset(-1px); }
      .portfolio-item:hover img {
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); }
      .portfolio-item:hover .caption {
        background-color: rgba(29, 128, 159, 0.9);
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); 
      } 
    }

.img-fluid{
  max-width: 100%;
  height:auto;
}

img{
  vertical-align: middle;
  border-style: none;
}

a {
  color: #1D809F; 
}
a:hover, a:focus, a:active {
  color: #155d74; 
}

.btn-primary {
  background-color: #1D809F !important;
  border-color: #1D809F !important;
  color: #fff !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #155d74 !important;
    border-color: #155d74 !important; }

.btn-secondary {
  background-color: #ecb807 !important;
  border-color: #ecb807 !important;
  color: #fff !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #ba9106 !important;
    border-color: #ba9106 !important; }

.btn-dark {
  color: #fff !important; }

.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700; }

/*.bg-primary {
  background-color: #1D809F !important; }*/

.social-link {
  display: block;
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color:#FFFFFF;/* #1D809F;*/
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
}
.social-link:hover{
  background-color: #155d74;
  text-decoration: none;
}

.social-link:hover > .so-icons{
  text-decoration: none;
  color:white !important;
}

.so-icons{
  color:#155D74;
  margin-top: 0.7rem !important;
}

.list-inline{
  background-color: #1d809f;
}



/*Theme 2*/

/*home button*/
.btn-primary1{
  border-color: grey !important;
  background-color: grey !important;
  color: #ffffff !important;
}

.btn-primary1:hover, .btn-primary1:focus, .btn-primary1:active {
  background-color: darkgrey !important;
  border-color: darkgrey !important; 
}

/*sidebar nav*/
.sidebar-wrapper1 {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: grey !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

/*Skills*/
.skills1{
  background-color: grey !important;
}

/*Service icons*/
.service-icon1 {
  background-color: #fff;
  color: grey;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
}

/*Portfolio item*/
.portfolio-item1 {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem; 
}
.portfolio-item1 .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1; 
}
.portfolio-item1 .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem; 
}
.portfolio-item1 .caption .caption-content h2 {
  font-size: 0.8rem;
  text-transform: uppercase; 
}
.portfolio-item1 .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem; 
}
@media (min-width: 992px) {
  .portfolio-item1 {
    max-width: none;
    margin: 0; 
  }
  .portfolio-item1 .caption {
    -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px); 
  }
  .portfolio-item1 .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem; 
  }
  .portfolio-item1 img {
    -webkit-transition: -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px); 
  }
  .portfolio-item1:hover img {
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem); 
  }
  .portfolio-item1:hover .caption {
    background-color: grey;
    opacity: 0.9;
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem); 
  } 
}

/*Contact Section*/
.contact1 {
  padding-top: 30px;
  width: 100%;
  height: auto;
  background-color: grey !important;
}

.list-inline1{
  background-color: grey;
}

.social-link1 {
  display: block;
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color:#FFFFFF;/* #1D809F;*/
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
}
.social-link1:hover{
  background-color: grey !important;
  text-decoration: none;
}

.social-link1:hover > .so-icons1{
  text-decoration: none;
  color:white !important;
}

.so-icons1{
  color: grey !important;
  margin-top: 0.7rem !important;
}

/*Theme3*/
.sidebar-wrapper2 {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: rgb(50,50,50) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.home2{
  background: url("https://sunnyleungkc.com/img/bg-front2.jpg") center center / cover !important;
}

.about2 {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  background-color: rgb(50,50,50)!important;
}

.skills2{
  background-color: rgb(70,70,70) !important;
}

.portfolio2 {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
  background-color: rgb(50,50,50) !important;
}

.portfolio2 > h2{
  color: #ffffff !important;
}

.contact2 {
  padding-top: 30px;
  width: 100%;
  height: auto;
  background-color: rgb(70,70,70) !important;
}

.list-inline2 {
  background-color: rgb(70,70,70);
}

.social-link2 {
  display: block;
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color:#FFFFFF;/* #1D809F;*/
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); 
}
.social-link2:hover{
  background-color: rgb(70,70,70) !important;
  text-decoration: none;
}

.social-link2:hover > .so-icons2{
  text-decoration: none;
  color:white !important;
}

.so-icons2{
  color: rgb(70,70,70) !important;
  margin-top: 0.7rem !important;
}